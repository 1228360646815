:root{
  --default-color: #00d4ff;
  --primary-color: #0094ff;
  --secundary-color: #0086e6;
  --tertiary-color: #0077cd;
  --quaternary-color: #0068b3;
  --quinary-color: #005999;
  --senary-color: #004a80;
  --septenary-color: #003c67;
  --warning-color: #FFCC00;
  --danger-color: #CC0000;
}

.app {
  padding: 0.5rem 2rem 2rem 2rem;
}

.header {
  text-align: center;
  margin-bottom: 2rem;
}

.header h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #777;
  font-weight: bold;
}

.header p{
  color: #777;
  font-weight: bold;
}

/* Forms */
.form-container {
  margin: 0 auto;
  min-height: 80%;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  max-width: 1200px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.inputs-container {
  width: 100%;
}

.inputs-container div {
  align-items: center;
  flex-direction: row;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.cardsShow{
  display: flex;
  flex-direction: column;
}

.form-container form {
  height: 100%;
  margin: 0 auto;
}

.form-control {
  width: 100%;
  margin-right: 2%;
  margin-left: 2%;
  justify-content: space-around;
  margin-bottom: 1rem;
}
.form-control-unic{
  width: 50%;
}
#JSON{
  justify-content: center;
}
.form-control div{
  width: 50%;
}
.form-control div select,
.form-control div input,
.form-control div label{
  width: 45%;
}
label {
  font-weight: bold;
  color: #777;
  font-size: 0.9rem;
}

input,
textarea,
select {
  box-sizing: border-box;
  padding: 0.6rem;
  width: 100%;
  border: none;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.6) 0px 2px 8px 0px;
}
textarea {
  height: 120px;
}

/* Actions */
.actions {
  margin-top: 3%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.actions button {
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 3px;
  background-color: #dfdfdf;
  cursor: pointer;
  transition: 0.3s;
}

.actions button:hover {
  background-color: #cfcfcf;
}

@media (max-width: 1000px) {
  .form-container{
    max-width: 85%;
  }
}
@media (max-width: 700px) {
  .form-container{
    max-width: 90%;
  }
  .form-control div{
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  .form-control div select, .form-control div input, .form-control div label {
    width: 90%;
  }
  #espace-control hr{
    width: 20%;
  }
}
@media (max-width: 600px) {
  .form-container{
    max-width: 100%;
  }
}