.card .btn-container-transacoes{
    width: 100%;
    margin-top: 3vh;
    display: flex;
    justify-content: space-evenly;
}
.card .btn-container-transacoes .custom-btn {
    height: 100%;
}
.card .btn-container-transacoes .custom-btn:hover {
    background: var(--primary-color);
    color: white;
}
.card .btn-container-transacoes .custom-btn.delete{
    border: 2px solid var(--danger-color);
    color: var(--danger-color);
    background: none;
}
.card .btn-container-transacoes .custom-btn.delete:hover{
    background: var(--danger-color);
    color: white;
}
.card .btn-container-transacoes .custom-btn.adicionarserv{
    background: none;
    border: 2px solid var(--primary-color);
}
.card .btn-container-transacoes .custom-btn.adicionarserv:hover{
    background: var(--primary-color);
    color: white;
}
.card .contentTransacoes {
    margin-top: 25px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

.card .contentTransacoes div {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: flex-start;
}
.card .contentTransacoes div div{
    width: 80%;
    margin-top: 10%;
    margin-right: 5%;
    align-content: center;
}