.container {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    animation: fadein 1s;
}

.form-control.card-control div{
    width: 20%;
    align-items: start;
    flex-direction: column;
    flex-wrap: wrap;
}
.form-control.card-control div input,
.form-control.card-control div label{
    width: unset;

}
.card {
    position: relative;
    margin: 2% auto;
    width: 80%;
    padding: 2%;
    box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.2);
}
.custom-btn {
    height: 100%;
    width: 30%;
    justify-content: center;
    font-size: 1em;
    padding: 0.8rem;
    border-radius: 5px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    opacity: 1;
    border: 2px solid var(--primary-color);
    color: var(--quaternary-color);
    cursor: pointer;
}

.content .custom-btn {
    height: 100%;
    width: 15%;
}
.content .custom-btn:hover {
    background: var(--primary-color);
    color: white;
}
.content .btn-container{
    width: 45%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
}
.btn-container .custom-btn{
    margin-left: 5%;
    width: 40%;
}
.custom-btn.delete{
    border: 2px solid var(--danger-color);
    color: var(--danger-color);
    background: none;
}
.custom-btn.delete:hover{
    background: var(--danger-color);
    color: white;
}
.custom-btn.adicionarserv{
    background: none;
    border: 2px solid var(--primary-color);
}
.custom-btn.adicionarserv:hover{
    background: var(--primary-color);
    color: white;
}
.title{
    margin-left: 3%;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.card:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
}

.card:hover .LabelCard {
    bottom: 0%;
    opacity: 1;
}

.circle {
    border-radius: 1%;
    width: 8%;
    height: 25%;
    background: black;
    position: absolute;
    right: 0px;
    top: 0;
    background-image: linear-gradient(to top, var(--primary-color) 0%, var(--default-color) 100%);
    border-bottom-left-radius: 170px;
}
.content {
    margin-top: 25px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.content div {
    display: flex;
    width: 25%;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
}

.content #TextoSuporte {
    text-align: center;
    width: 10%;
}

h1 {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 0;
}

h2 {
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: 300;
}

.social {
    margin-bottom: 5px;
}

.footer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
}

a {
    text-decoration: none !important;
    color: black;
    margin-left: 8px;
    font-weight: 300;
}

i {
    font-weight: 400;
}



.location {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

i {
    color: red;
}

p {
    font-weight: 300;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.3;
    }

    75% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 1115px) {
    .form-control.card-control div{
        width: 85%;
    }
    .form-control.card-control div input{
        width: 100%;
    }
    .form-control.card-control{
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }

    /* Ajustar os cards */
    .circle{
        height: 15%;
    }
    .inputs-container .content{
        flex-direction: row;
    }
    .content div{
        margin: 0 5% 0 5%;
        width: 40%;
    }
    .content .btn-container{
        width: 100%;
    }
    .btn-container .custom-btn{
        margin: 5%;
    }
}