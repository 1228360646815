.tableContainer {
    overflow: scroll;
}
.inputs-container .headerTable{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    margin-bottom: 20px;
}
.inputs-container .headerTable select{
    padding: 0.6rem;
    width: 100%;
    border: none;
    border-radius: 3px;
    box-shadow: rgba(99, 99, 99, 0.6) 0px 2px 8px 0px;
}
.inputs-container .headerTable h1{
    width: 50%;
    font-weight: bold;
    color: #777;
    text-align: center;
}
.inputs-container .headerTable div{
    width: 50%;
}
table {
    max-width: 100%;
    table-layout: fixed;
    border-radius: 20px;
    border-collapse: separate;
    padding: 0 20px 20px 20px;
    text-align: center;
    overflow: scroll;
}
thead .sticky-box,
table .sticky-box{
    background-color: #fff;
    position: sticky;
    border-left: 1px solid #003c67;
    border-bottom: 1px solid #003c67;
    left: 0;
    z-index: 3;
}
thead .sticky-box{
    background-color: #0094ff;
}
thead tr th {
    background-color: #0094ff;
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    border-top: 1px solid #003c67;
    border-right: 1px solid #003c67;
    border-bottom: 1px solid #003c67;
}

table td {
    min-width: 120px;
    border-right: 1px solid #003c67;
    border-bottom: 1px solid #003c67;
}

table td:first-child {
    border-left: 1px solid #003c67;
}

table td:last-child {
    border-right: 1px solid #003c67;
}

.InputTable{
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: none;
    box-shadow: none;
}