.container .Modalback {
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    background-color: transparent;
    background-color: rgba(0,0,0,0.4); 
    z-index: 2;
}

.InputsOVsModal {
    background-color: #fff;
    max-height: 90%;
    overflow-y: auto;
    width: 70%;
    border-radius: 2%;
    position: fixed;
    z-index: 1;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
}

@media (max-width: 1000px) {
    .InputsOVsModal{
        width: 80%;
        overflow-x: hidden;
    }
}