.navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.userDetails div {
    margin-right: 10px;
}

.userDetails div .userImage {
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.userDetails div .msg {
    font-weight: bold;
    font-size: 1.1rem;
    text-decoration: inherit !important;
    color: #777;
}

.userDetails div .userName {
    color: blue;
    text-transform: unset ! important;
}

.userDetails div #basic-menu .NavMenuItem .exitIcon {
    height: 100px;
    width: 100px;
    background-color: black;
}

.navBar .LinkContainer {
    display: flex;
    justify-items: flex-start;
}

.navBar div a{
    font-size: 1.0rem;
    font-weight: bold;
    color: #777777;
    padding: 6px;
}

.navBar div a .barra {
    content: "";
    opacity: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--primary-color);
    transition: width 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.navBar div .activeLink .barra,
.navBar div a:hover .barra {
    animation: slideUp 0.5s ease-in-out forwards;
}


@keyframes slideUp {
    0% {
        transform: translateX(-100%);
        opacity: 0;
        width: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
        width: 100%;
    }
}

@media (max-width: 1115px) {
    .navBar div a{
        padding: 4px;
    }
}