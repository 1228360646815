.input-file {
    width: 5%;
    opacity: 0;
    padding: 14px 0;
    cursor: pointer;
}

.input-file:hover+.input-file-trigger,
.input-file:focus+.input-file-trigger,
.input-file-trigger:hover,
.input-file-trigger:focus {
    background-color: #34495e;
    color: white;
}

.input-file-trigger {
    padding: 14px 45px;
    background: var(--primary-color);
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
  }

  hr{
    color: var(--primary-color);
    background: var(--primary-color);
    height: 5px;
    width: 25%;
}

#espace-control{
    white-space: nowrap;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
}

#espace-control hr{
    width: 25%;
}
#espace-control div{
    color: #777;
    font-weight: bold;
}