.form-container-input {
    display: flex;
    margin-bottom: 4%;
}

.form-container-input .closemodalSty {
    position: sticky;
    left: 100%;
    display: flex;
    justify-content: flex-end;
    right: 0;
}

.closeModal {
    font-size: 1em;
    padding: 0.8rem;
    background-color: transparent;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    border: none;
    color: var(--quaternary-color);
    cursor: pointer;
    right: 0;
    left: 100%;
}

.form-control.formTitulo {
    margin: 5%;
}

.inputs-container .Titulo {
    position: relative;
    justify-content: center;
    font-weight: bold;
    color: #777;
    font-size: 1.2rem;
}

.form-container-input .form-control .label-container,
.inputs-container div .form-control .label-container {
    width: 45%;
    box-sizing: content-box;
    flex-direction: column;
}
.form-container-input .form-control .label-container label,
.inputs-container div .form-control .label-container label {
    width: 100%;
}

.form-container-input .form-control .label-container .label-help,
.inputs-container div .label-container .label-help {
    font-style: italic;
    font-size: 0.8rem;
}

@media (max-width: 1000px) {

    .form-container-input .form-control .label-container,
    .inputs-container div .form-control .label-container {
        width: 90%;
        flex-direction: row;
        margin: unset;
    }

    .form-container-input .form-control .label-container label {
        width: unset;
    }

    

    .form-container-input .form-control {
        display: flex;
        flex-direction: column;
        margin-bottom: unset;
    }

    .form-container-input .form-control div {
        width: 100%;
        margin: 2% 5% 0 5%;
    }

    .form-container-input .form-control input,
    .form-container-input .form-control select {
        width: 90%;
    }
}

@media (max-width: 700px) {
    .form-container-input .form-control div {
        margin: 1%;
    }
}